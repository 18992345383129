import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _83dfe29a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _12bb0ebd = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _09363fec = () => interopDefault(import('../pages/level.vue' /* webpackChunkName: "pages/level" */))
const _3729026a = () => interopDefault(import('../pages/player.vue' /* webpackChunkName: "pages/player" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _0aabdba6 = () => interopDefault(import('../pages/server.vue' /* webpackChunkName: "pages/server" */))
const _d5336054 = () => interopDefault(import('../pages/steam.vue' /* webpackChunkName: "pages/steam" */))
const _bae6a392 = () => interopDefault(import('../pages/translation.vue' /* webpackChunkName: "pages/translation" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _83dfe29a,
    name: "account"
  }, {
    path: "/contacts",
    component: _12bb0ebd,
    name: "contacts"
  }, {
    path: "/level",
    component: _09363fec,
    name: "level"
  }, {
    path: "/player",
    component: _3729026a,
    name: "player"
  }, {
    path: "/privacy",
    component: _37e8fae4,
    name: "privacy"
  }, {
    path: "/server",
    component: _0aabdba6,
    name: "server"
  }, {
    path: "/steam",
    component: _d5336054,
    name: "steam"
  }, {
    path: "/translation",
    component: _bae6a392,
    name: "translation"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
